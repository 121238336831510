import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import Modelbox from "../../utilityComponents/Modelbox";


const InvoiceAccordionExpanded = function ({
  invoice,
  disable,
  // setSubmitted,
  saveChanges,
  closeView,
  // handleInputChange,
}) {
  // storing the invoice data in tempInvoice
  const [tempInvoice, setTempInvoice] = useState(invoice);
  const [Submitted, setSubmitted] = useState(invoice.isSubmitted);

  // Changing the format from parse date to normal date format 
  function formatDateWithTime(date) {
    if (!date) return '';

    return dayjs(date).format('YYYY-MM-DD');
  }
  const closeview = () => {
    closeView();
  };

  return (
    <div>
      <div className="bg-slate-100  lg:ml-10 sm:ml-10 lg:mr-10 sm:mr-2 py-6 mb-5">
        <div className="bg-white   shadow-blue-600/90 dark:shadow-lg dark:shadow-blue-800/90 mx-4 py-8  rounded-lg ml-10 mr-11 shadow-sm">
          <div className="flex ml-4 mb-4 font-bold">
            <h1 className="">Submitted</h1>
            <div className="ml-4 w-5">
              <input
                type="Checkbox"
                title="Yes"
                className=""
                disabled={disable}
                checked={tempInvoice.isSubmitted}
                //set the input data non editable when check box is checked and editable when check box is not checked
                onChange={(e) => {
                  setTempInvoice((curVal) => {
                    return { ...curVal, isSubmitted: !tempInvoice.isSubmitted };
                  });
                  // setChecked(!checked);
                  setSubmitted(!Submitted);
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6  ml-4 mr-4">
            <div>
              <h1 className="mb-2 text-gray-700">Invoice No</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl ">
                <input
                  type="text"
                  value={tempInvoice.invoiceNo}
                  className="w-full focus:outline-none"
                  disabled={Submitted ? true : disable}
                  // fetching the invoice number from api through invoice data
                  onChange={(e) => {
                    setTempInvoice((curVal) => {
                      return { ...curVal, invoiceNo: e.target.value };
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-gray-700">Generated Date</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="date"
                  value={formatDateWithTime(tempInvoice.generatedDate)}
                  //fetching the generated date from api inside the  input 
                  onChange={(e) => {
                    setTempInvoice((curVal) => {
                      return { ...curVal, generatedDate: e.target.value };
                    });
                  }}
                  disabled={Submitted ? true : disable}
                  className="w-full focus:outline-none"
                  placeholderText="Select a date"
                />
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-gray-700">Work Location</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="text"
                  value={tempInvoice.workLocation}
                  className="w-full focus:outline-none"
                  disabled={Submitted ? true : disable}
                  //fetching the worklocation from api 
                  onChange={(e) =>
                    setTempInvoice((curVal) => {
                      return { ...curVal, workLocation: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-gray-700">Work Order</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="text"
                  value={tempInvoice.workOrder}
                  className="w-full focus:outline-none"
                  disabled={Submitted ? true : disable}
                  //fetching the wororder from api
                  onChange={(e) =>
                    setTempInvoice((curVal) => {
                      return { ...curVal, workOrder: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <div>
              <h1 className="mb-2 text-gray-700">Invoice Due Date</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">

                <input
                  type="date"
                  // fetching the invoice due date from invoice and changing the format from parse date to normal date
                  value={formatDateWithTime(tempInvoice.invoiceDueDate)}
                  onChange={(e) =>
                    setTempInvoice((curVal) => {

                      return { ...curVal, invoiceDueDate: e.target.value };
                    })
                  }
                  dateFormat="MM/dd/yyyy"
                  disabled={Submitted ? true : disable}
                  className="w-full focus:outline-none"
                  placeholderText="Select a date"
                />
              </div>
            </div>

            <div>
              <h1 className="mb-2 text-gray-700">Full Project Name</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="text"
                  value={tempInvoice.fullProjectName}
                  className="w-full focus:outline-none"
                  disabled={Submitted ? true : disable}
                  //fetching the project name from api 
                  onChange={(e) =>
                    setTempInvoice((curVal) => {
                      return { ...curVal, fullProjectName: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <div>
              <h1 className="mb-2 text-gray-700">Attn</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="text"
                  value={tempInvoice.attn}
                  className="w-full focus:outline-none"
                  disabled={Submitted ? true : disable}
                  //fetching the attn from invoice data
                  onChange={(e) =>
                    setTempInvoice((curVal) => {
                      return { ...curVal, attn: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-gray-700">Delivery Date</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="date"
                  //changing the delivary date from parse date to normal date format
                  value={formatDateWithTime(tempInvoice.delivaryDate)}
                  //fetching the delivery date from api
                  onChange={(e) =>
                    setTempInvoice((curVal) => {
                      return { ...curVal, delivaryDate: e.target.value };
                    })
                  }
                  dateFormat="MM/dd/yyyy"
                  disabled={Submitted ? true : disable}
                  className="w-full focus:outline-none"
                  placeholderText="Select a date"
                />
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-gray-700">No of Footage</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="text"
                  value={tempInvoice?.footage}
                  className="w-full focus:outline-none"
                  disabled={Submitted ? true : disable}
                  //fetching the footage from api
                  onChange={(e) =>
                    setTempInvoice((curVal) => {
                      return { ...curVal, footage: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <div>
              <h1 className="mb-2 text-gray-700">Billing 100% Footage</h1>
              <div className="w-full p-2 border rounded-lg shadow-xl">
                <input
                  type="text"
                  value={tempInvoice.billingFootage}
                  className="w-full focus:outline-none"
                  disabled={Submitted ? true : disable}
                  //fetching the billing footage from api
                  onChange={(e) =>
                    setTempInvoice((curVal) => {
                      return { ...curVal, billingFootage: e.target.value };
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex  justify-end space-x-2 mr-5 ">
            <button
              onClick={closeview}
              className="text-lg px-5 text-gray-500">Cancel</button>
            <button

              //saving all the invoice data when button save button is clicked
              onClick={() => {
                saveChanges(tempInvoice);
                // closeview();
              }}
              className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 text-white rounded-full px-5 py-1.5 text-md"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div >
  );
};
export default InvoiceAccordionExpanded;
