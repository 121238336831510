
import { createBrowserRouter, RouterProvider, } from 'react-router-dom';
import Layout from "./components/pages/layout/Layout"
import Login from "./components/pages/login/Login"
import Home from './components/pages/home/Home';
import Jobs from './components/pages/jobs/Jobs';
import { Provider } from 'react-redux';
import store from "./store/store";
import Invoicespage from './components/pages/invoices/Invoicespage';
import Landing from './components/pages/landing/Landing';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RequireAuth from './components/utilityComponents/RequireAuth';
import { Toaster } from 'react-hot-toast';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/jobs/:project',
    element: <Jobs />
  },
  {
    path: '/invoice/:jobId',
    element: <Invoicespage />
  }

])
function App() {

  return (<>
    {/* <Provider store={store}>
      <RouterProvider router={router} />
    </Provider> */}
    <Provider store={store}>
      <BrowserRouter>
        <Toaster position="top-right" reverseOrder={false} />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="/login" element={<Login />} />
            {/* <Route exact path="/forgotpassword" element={<ForgotPassword />} /> */}
            <Route element={<RequireAuth />}>
              <Route path="/home" element={<Home />} />
              <Route path="/jobs/:project" element={<Jobs />} />
              <Route path='invoice/:project/:jobId' element={<Invoicespage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </>)
}
export default App;