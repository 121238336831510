import { useNavigate, Link } from "react-router-dom";
import { useContext, useEffect } from "react";


const Landing = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/login");
    }, [])
    return (
        <></>
    )
}

export default Landing
