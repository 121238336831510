import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    show : false
}
const loadingSlice = createSlice({
    name: "loadingBar",
    initialState,
    reducers: {
        setLoadingBarStatus: (state, action) => {
            console.log("action", action.payload.show)
            state.show = action.payload.show
        },
       
    }
})

export const { setLoadingBarStatus } = loadingSlice.actions
export default loadingSlice.reducer