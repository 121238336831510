import React, { useEffect } from "react";

import { useState } from "react";
import dayjs from "dayjs";
import {
  uparrow,
  down,
  editActive,
  editInactive,
  Downloadblue,
} from "../../../assets";
import { useParams } from "react-router-dom";

// props from invoices page
const InvoicesAccordionHeader = function ({
  invoice,
  toggle,
  renderModalBox,
  openModal,
  closeModal,
  setIsExpanded,
  setDisable,
  editMode,
  setEditMode,
}) {
  const [visible, setVisible] = useState();
  //set edit mode false initially.
  // const [editMode, setEditMode] = useState(false);
  const { jobId } = useParams()
  const arrowVisible = () => {
    setVisible(!visible);
  };

  // changing the date format from parse to dd-mm-yyyy format
  const dates = invoice.generatedDate;
  const formattedDateWithTime = dayjs(dates).format("YYYY-MM-DD");

  const closeview = () => {
    openModal();
  };

  const downloadInvoice = async (type) => {
    try {
      const response = await fetch(
        `/api/invoices/type${type}/download/${jobId}`
      );
      if (!response.ok) throw new Error("Error downloading the file");

      const blob = await response.blob();
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "invoice.pdf"; // Default filename

      // Extract filename from Content-Disposition
      if (contentDisposition && contentDisposition.includes("filename=")) {
        filename = contentDisposition.split("filename=")[1].replace(/"/g, "");
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Use dynamic filename
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  //edit mode true when edit icon is clicked
  useEffect(() => {
    if (editMode) {
      setIsExpanded(true);
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [editMode]);

  useEffect(() => {
    if (!visible) {
      setEditMode(false);
    }
  }, [visible]);

  return (
    <div className="lg:ml-10 sm:ml-10 sm:mr-10 flex flex-row justify-between bg-slate-50 py-5 mt-4 lg:mr-10  shadow-sm shadow-blue-800/50 ">
      <div className="ml-10">{invoice.invoiceType}</div>
      <div className="ml-20">{invoice.invoiceNo}</div>
      <div className="ml-10">{formattedDateWithTime}</div>
      <div className="ml-11">Changes made</div>

      <div className="flex sm:flex md:flex ">
        <div>
          <div
            onClick={() => {
              setEditMode(!editMode);
              setVisible(true);
            }}
          >
            <img
              src={editMode ? editActive : editInactive}
              alt="edit"
              className=" cursor-pointer h-6"
            ></img>
          </div>
        </div>
        <div onClick={() => downloadInvoice(invoice.invoiceType)}>
          <img
            src={Downloadblue}
            alt="downloadicon"
            className="h-6 ml-2  cursor-pointer"
          ></img>
        </div>
      </div>
      <div>
        <div
          className=""
          style={{
            paddingRight: !invoice.isSubmitted ? "6px" : "0",
          }}
        >
          {invoice.isSubmitted ? "Yes" : "No"}
        </div>
      </div>
      <div className="mr-10">
        {visible ? (
          <img
            src={down}
            alt="arrowdown"
            // src={dropdown}
            className="h-2 mt-1.5 cursor-pointer"
            onClick={() => {
              // editClick(true);
              toggle(invoice.invoiceType);
              setVisible(!visible);
              // renderModalBox(invoice.invoiceType);
            }}
          ></img>
        ) : (
          <img
            src={uparrow}
            alt="uparrowicon"
            className="h-2 mt-2 cursor-pointer"
            onClick={() => {
              // editClick(true);
              toggle(invoice.invoiceType);
              setVisible(!visible);
              // renderModalBox(invoice.invoiceType);
            }}
          ></img>
        )}
      </div>

    </div>
  );
};

export default InvoicesAccordionHeader;
