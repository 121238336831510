import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { companyLogo } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { addProjects, setSelectedProject } from "../../../store/projectSlice";
import Navbar from "../../utilityComponents/Navbar";
const Home = () => {
  const [project, setProject] = useState("Lumos")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectNames, selectedProject } = useSelector((state) => state.projects)
  // useEffect(() => {
  //   async function getProjectIds() {
  //     const response = await fetch("/api/project")
  //     const projectData = await response.json()
  //     console.log("projectData", projectData)
  //     dispatch(addProjects({ ...projectData }))
  //   }
  //   getProjectIds()
  // }, [])
  const { isAuthenticated } = useSelector(
    (state) => state.auth
  );

  // useEffect(() => {

  //   if (selectedProject) navigate(`/jobs/${selectedProject}`)
  // }, [selectedProject])


  return (
    <div className="font-fontFamily">

      <Navbar />
      <main className="flex justify-center">
        <div className="bg-white w-full sm:w-11/12 md:w-9/12 lg:w-6/12 xl:w-5/12 p-10 shadow-2xl mt-36">
          <div className="text-center mb-14">
            <h1 className="text-2xl mb-8 mt-16  font-semi-bold ">
              Report Generator
            </h1>
          </div>

          <div className="mb-6 lg:ml-24 ">
            <label className="block text-lg  lg:ml-4 sm:ml-0 md:ml-0 mb-2">
              Project
            </label>
            {/* use drop down component all the options available in projectnames */}
            <select
              id=""
              value={selectedProject ? selectedProject : "None"}
              onChange={(e) => {
                // setProject(e.target.value)
                dispatch(setSelectedProject({ selectedProject: e.target.value }))
              }}
              className="w-full lg:w-3/4 sm:w-full mt-2 p-2 lg:ml-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="Lumos">Lumos</option>
              {/* <option value="2">Choose 2</option>
              <option value="3">Choose 3</option>
              <option value="4">Choose 4</option> */}
            </select>
          </div>

          <div className="text-center">
            <button
              className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80  text-white px-6 py-2 rounded-full mt-6 mb-20"
              onClick={() => {
                if (selectedProject) navigate(`/jobs/${selectedProject}`)
              }}
            >
              View Data
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
