// authSlice.js (Redux slice)
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
    password: '',
    isAuthenticated: false,
    error: '',
    token: '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
            state.error = '';
        },
        setPassword: (state, action) => {
            state.password = action.payload;
            state.error = '';
        },
        setAuthentication: (state, action) => {
            state.isAuthenticated = action.payload.isAuthenticated;
            state.token = action.payload.token || '';
            state.error = action.payload.error || '';
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.token = '';
            state.email = '';
            state.password = '';
            state.error = '';
        },
    },
});

export const { setEmail, setPassword, setAuthentication, logout } = authSlice.actions;

export default authSlice.reducer;