import { useNavigate, useParams } from "react-router-dom";
import "boxicons";
import Navbar from "../../utilityComponents/Navbar.jsx"
import Headerjobs from "./Headerjobs.jsx";
import Invoices from "./Invoices.jsx";
import InvoicesTableheader from "./InvoicesTableheader.jsx"
import { useDispatch } from "react-redux";

const Invoicespage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  return (
    <div className="font-fontFamily overflow-x-auto ">
      <Navbar />
      <div>
        <Headerjobs />
      </div>
      <InvoicesTableheader />
      <div>
        <Invoices />
      </div>
    </div>
  );
};
export default Invoicespage;
