import { configureStore } from '@reduxjs/toolkit'
import { loadingBarReducer, projectsReducer } from './index'
import authReducer from "./authSlice";

export default configureStore({
    reducer: {
        projects: projectsReducer,
        auth: authReducer,
        loadingBar: loadingBarReducer,
    },
})