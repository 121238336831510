import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setLoadingBarStatus } from '../../store/loadingSlice';
import toast, { Toaster } from 'react-hot-toast';
const InvoiceStatus = ({ requestId, setUpdate, setRequestId }) => {
    const [statusData, setStatusData] = useState(null); // Store API response
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [polling, setPolling] = useState(true); // Polling control
    const dispatch = useDispatch();
    useEffect(() => {
        if (!requestId) {
            setError('Request ID is missing');
            return;
        }

        const pollStatus = async () => {
            try {
                const dataJosn = await fetch(`/invoice-status/${requestId}`)
                const data = await dataJosn.json()
                console.log("data", data)
                if (data) {
                    setStatusData(data);
                    setError(null);
                    if (data.invoices.length === 0) {
                        setUpdate()
                        setPolling(false); // Stop polling
                        toast('No Invoices for generation')
                        setRequestId(null)
                        return;
                    }
                    // Stop polling if all invoices are generated
                    const allGenerated = data.invoices.every(
                        (invoice) => (invoice.status === 'Completed' || invoice.status === 'Failed')
                    );
                    console.log("allGenerated", allGenerated)
                    if (allGenerated) {
                        setUpdate()
                        setPolling(false); // Stop polling
                        toast.success('Invoice generated successfully')

                    }
                } else {

                    dispatch(setLoadingBarStatus({ show: false }));
                    toast('Unknown error')
                    setRequestId(null)
                }
            } catch (err) {
                dispatch(setLoadingBarStatus({ show: false }));
                toast.error('Unknown error')
                setRequestId(null)
                console.error(err);
            }

        };

        if (polling) {
            const intervalId = setInterval(pollStatus, 3000); // Poll every 5 seconds
            return () => clearInterval(intervalId); // Cleanup on unmount or stop polling
        } else {
            dispatch(setLoadingBarStatus({ show: false })); // Hide loading ba
            setRequestId(null)
        }
    }, [requestId, polling]);

    return (
        <>
            <Toaster />
        </>
    );
};

export default InvoiceStatus;
