import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuthentication } from "../store/authSlice";

function useAuth() {
    const dispatch = useDispatch();
    const [token, setToken] = useState(() => localStorage.getItem("accessToken") || null);

    // Save token to localStorage and update Redux state
    const saveToken = (newToken) => {
        setToken(newToken);
        localStorage.setItem("accessToken", newToken);
        dispatch(setAuthentication({ isAuthenticated: true, token: newToken }));
    };

    // Remove token from localStorage and update Redux state
    const clearToken = () => {
        setToken(null);
        localStorage.removeItem("accessToken");
        dispatch(setAuthentication({ isAuthenticated: false, token: null }));
    };

    // Sync token with Redux state when the component mounts
    useEffect(() => {
        if (token) {
            dispatch(setAuthentication({ isAuthenticated: true, token }));
        }
    }, [token, dispatch]);

    return { token, saveToken, clearToken };
}

export default useAuth;
