import { Outlet } from "react-router-dom"
import LoadingBar from "../../utilityComponents/LoadingBar";

const Layout = () => {

    return (
        <main >
            
            <Outlet />
            <LoadingBar/>

        </main>
    )
}



export default Layout;

