import React from "react";

const InvoicesTableheader = () => {
  return (
    // <div className="  font-semibold text-lg border-b-2 border-orange-500 text-wrap lg:mr-10 mt-10 py-5 flex ml-10 text-left flex-row ">
    //     <div className="">Invoice Type</div>
    //     <div className=""> Invoice Name</div>
    //     <div className="">Created Date</div>
    //     <div className=""> Status</div>
    //     <div className="">Actions</div>
    //     <div className="">Submitted</div>
    // </div>
//     <div className="font-semibold text-lg border-b-2 border-orange-500  mt-10 py-5 flex flex-wrap   lg:mr-10 ml-10 text-left">
//     <div className="lg:ml-2 lg:mr-10 sm:ml-4  ">Invoice Type</div>
//     <div className="lg:ml-44 lg:mr-10 sm:ml-4">Invoice Name</div>
//     <div className="lg:ml-48 lg:mr-16 sm:ml-4">Created Date</div>
//     <div className="lg:ml-40 lg:mr-16 sm:ml-4">Status</div>
//     <div className="lg:ml-48  lg:mr-10 sm:ml-4">Actions</div>
//     <div className="lg:ml-36 sm:ml-4">Submitted</div>
// </div>
<div className="font-semibold text-lg border-b-2 border-orange-500 mt-10 py-5 flex flex-wrap text-left ml-10 mr-10">
  <div className="w-full sm:w-auto lg:ml-2 lg:mr-10 sm:ml-4">Invoice Type</div>
  <div className="w-full sm:w-auto lg:ml-44 lg:mr-10 sm:ml-4">Invoice Name</div>
  <div className="w-full sm:w-auto lg:ml-48 lg:mr-16 sm:ml-4">Created Date</div>
  <div className="w-full sm:w-auto lg:ml-40 lg:mr-16 sm:ml-4">Status</div>
  <div className="w-full sm:w-auto lg:ml-48 lg:mr-10 sm:ml-4">Actions</div>
  <div className="w-full sm:w-auto lg:ml-36 sm:ml-4 max-[400px]:">Submitted</div>
</div>
  );
};
export default InvoicesTableheader;

