// RequireAuth.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

function RequireAuth() {
    const { token } = useAuth();

    // If token doesn't exist, redirect to login page
    if (!token) {
        return <Navigate to="/login" replace />;
    }

    // Otherwise, render the protected content (Outlet will render nested routes)
    return <Outlet />;
}

export default RequireAuth;
