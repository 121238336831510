import React from "react";
import { MoonLoader } from 'react-spinners'
import { useSelector } from "react-redux";
const LoadingBar = () => {
    const { show } = useSelector((state) => state.loadingBar)
    return (
        <div>
            {show ? (<div className="fixed inset-0 flex items-center justify-center  bg-black bg-opacity-15 shadow-lg">
                <div className="bg-slate-50  pt-7 pl-8 pr-7 pb-6 shadow-lg ">
                    <MoonLoader color="#124ee6" size={60} />
                    <div className="mt-3 ml-1 font-semibold text-gray-500">Loading.....</div>
                </div>
            </div>) : ''}
        </div>
    )
}
export default LoadingBar