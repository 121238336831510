import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import ReadExcel from "./ReadExcel";
import { companyLogo, line, Search, Downloadblue } from "../../../assets";
import Navbar from "../../utilityComponents/Navbar";
import { setLoadingBarStatus } from "../../../store/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import InvoiceStatus from "../../utilityComponents/InvoiceStatus";
import { Back } from "../../../assets";
import toast, { Toaster } from 'react-hot-toast';

const Jobs = () => {

  const columns = [
    {
      name: "Katapult",
      selector: (row) => row.jobId,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "Area",
      selector: (row) => row.area,
      sortable: true,
    },
    {
      name: <div>Job name</div>,
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: "Route",
      selector: (row) => row.route,
      sortable: true,
    },
    {
      name: "Work order",
      selector: (row) => row.workOrder,
      sortable: true,
    },
    {
      name: <div className="ml-3">Action</div>,
      cell: (row) => (
        <div className="flex">
          <button onClick={() => navigate(`/invoice/${project}/${row.jobId}`)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.41421 15.89L16.5563 5.74786L15.1421 4.33364L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6473L14.435 2.21232C14.8256 1.8218 15.4587 1.8218 15.8492 2.21232L18.6777 5.04075C19.0682 5.43127 19.0682 6.06444 18.6777 6.45496L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"
                fill="#008DFE"
              />
            </svg>
          </button>
          <button onClick={() => { downloadInvoices(row.jobId) }}>
            <img
              src={Downloadblue}
              className="h-6 hover:h-6 ml-2"
              alt="download"
            ></img>
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
        border: "none",
        display: "flex",
        alignItems: "center",
      },
    },
    headCells: {
      style: {
        backgroundColor: "",
        color: "black",
        fontWeight: "",
        padding: "9px",
        paddingLeft: "8px",
        fontSize: "15px",
        text: "black",
        borderBottom: "2px solid orange",
        justifyContent: "left",
        wordWrap: "break-word",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        padding: "10px",
        backgroundColor: "#FFFFFF",
        color: "black",
        border: "none",
        borderBottom: "none",
        textAlign: "center",
        lineHeight: "normal",
      },
    },
  };

  const navigate = useNavigate();
  const [exceldata, setExcelData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [requestId, setRequestId] = useState("")
  const { project } = useParams();
  const dispatch = useDispatch();
  const { projectNames, selectedProject } = useSelector((state) => state.projects)
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("No file selected!");
      return;
    }

    const validTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    if (!validTypes.includes(file.type)) {
      alert("Please upload a valid Excel file.");
      return;
    }
    setFile(file);
    const reader = new FileReader();

    dispatch(setLoadingBarStatus({ show: true }));
    reader.onload = (e) => {
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "array" });

        // Parse the first sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        console.log("Excel data:", jsonData);
        setExcelData(jsonData);
        setIsModalOpen(true);
      } catch (error) {
        console.error("Error reading Excel file:", error);
        alert("There was an error processing the file. Please try again.");
      }
      finally {
        dispatch(setLoadingBarStatus({ show: false }));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const excelFileUpload = () => {
    const inp = document.createElement("input");
    inp.type = "file";
    inp.accept = ".xlsx";
    inp.enctype = "multipart/form-data";
    inp.addEventListener("change", function (event) {
      handleFileUpload(event);
    });
    inp.click();
  };

  function closeModel() {
    setIsModalOpen(false);
  }

  const handleSearch = (query) => {
    setSearchQuery(query);
    const lowercasedQuery = query.toLowerCase();
    const filtered = jobData.filter((item) =>
      ["jobId", "country", "jobName", "area", "workOrder"]
        .map((key) => item[key]?.toLowerCase().includes(lowercasedQuery))
        .some(Boolean)
    );
    setFilteredRecords(filtered);
  }

  const downloadInvoices = async (jobId) => {
    try {
      const response = await fetch(`/api/jobs/job/download/${jobId}`);
      if (!response.ok) throw new Error('Error downloading the ZIP file');

      const blob = await response.blob();
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = 'invoice.zip'; // Default filename

      // Extract filename from Content-Disposition header
      if (contentDisposition && contentDisposition.includes('filename=')) {
        filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename; // Use the dynamic filename
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  const uploadAndGenerateInvoice = async (type) => {

    if (!file) {
      alert("Please upload a file first!");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    const uploadInvoice = async (url) => {
      try {
        dispatch(setLoadingBarStatus({ show: true })); // Show loading bar
        const responseJson = await fetch(url, {
          method: "POST",
          body: formData,
        });

        const response = await responseJson.json();
        if (response.status === 201) {
          //now check for status for the batch
          setRequestId(response.data.requestId)
          // setUpdate(!update);

        } else {
          alert("Failed to upload file!");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        dispatch(setLoadingBarStatus({ show: false }));
        alert("Error uploading file!");
      }
    }


    if (type === "typeA") {
      uploadInvoice(`/api/invoices/typeA/Lumos`)
    } else if (type === "typeB") {
      uploadInvoice(`/api/invoices/typeB/Lumos`)
    } else {
      uploadInvoice(`/api/invoices/typeC/Lumos`)
    }




  };

  useEffect(() => {
    console.log("updated")
    async function getJobs() {
      dispatch(setLoadingBarStatus({ show: true })); // Show loading bar

      try {
        console.log("project", project)
        const response = await fetch(`/api/jobs/${project}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const jobsResponse = await response.json();
        if (jobsResponse.status === 200) {
          setJobData(jobsResponse.data.projects[0].jobs);
          setFilteredRecords(jobsResponse.data.projects[0].jobs);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
        // Optionally handle errors (e.g., show a toast notification or update state with an error message)
      } finally {
        dispatch(setLoadingBarStatus({ show: false })); // Hide loading bar
      }
    }
    getJobs();

  }, [update]);

  return (
    <div className="font-fontFamily">
      {/* use navbar component */}
      <div>
        <Navbar />
      </div>
      <div className="">
        <div className="flex">
          <img
            src={Back}
            className="h-5 mt-10 ml-10 cursor-pointer"
            alt="back"
            onClick={() => navigate(`/home`)}
          ></img>
          <h1 className="font-bold mt-9 ml-2"></h1>
        </div>
        <div className="flex justify-between">
          <h1 className="mt-6 ml-10 text-lg font-semibold font-fontFamily">
            {/* include project name and katapult id */}
            Lumos (M001)
          </h1>
        </div>

        <div className=" flex mt-10 ml-10 ">
          <h1 className="mt-2 mr-4 text-lg font-semibold"> Jobs </h1>
          <div>
            <img
              src={line}
              alt="line"
              className="h-5 mt-3 mr-8 cursor-pointer"
            ></img>
          </div>
          <div>
            <div className="flex p-2   border  rounded-lg shadow-xl ">
              <img
                className="h-5 cursor-pointer"
                alt="search"
                src={Search}
              ></img>
              <div>
                <img src={line} alt="line" className="h-5 "></img>
              </div>
              <div>
                {/* make a component for search */}
                <input
                  type="text"
                  className="focus:outline-none"
                  placeholder="Type for search"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end ml-9">
            <button
              className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700  shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 text-white rounded-full  shadow-xl px-5 cursor-pointer"
              onClick={excelFileUpload}
            >
              Upload Excel
            </button>
          </div>
        </div>
      </div>
      <div className="ml-10 mr-10 mt-10 ">
        <DataTable
          columns={columns}
          data={filteredRecords}
          selectableRows
          sortable
          customStyles={customStyles}
          pagination
        />
      </div>
      <div>
        {/* make it big */}
        {isModalOpen ? (
          <ReadExcel
            data={exceldata}
            uploadAndGenerateInvoice={uploadAndGenerateInvoice}
            close={closeModel}
          />
        ) : null}
      </div>
      {requestId ? <InvoiceStatus requestId={requestId} setRequestId={setRequestId} setUpdate={() => {
        setUpdate(!update);
        setIsModalOpen(false)
        setRequestId(null);
      }} /> : null}
    </div>
  );
};
export default Jobs;