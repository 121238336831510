import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projectNames: [],
    selectedProject: "Lumos",
}
const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        addProjects: (state, action) => {
            console.log("action", action.payload.projects)
            state.projectNames = [...action.payload.projects]
        },
        setSelectedProject: (state, action) => {
            console.log("action", action.payload.selectedProject)
            state.selectedProject = action.payload.selectedProject
        },

    }

})

export const { addProjects, setSelectedProject } = projectSlice.actions
export default projectSlice.reducer